/**
 * Finish page
 * 
 * If the hunt is still running, asks if the team is done and is ready to stop the game.
 * 
 * If the hunt is ended, it shows them their score, a card where they can submit a review,
 * and card where it thanks them for playing, with links to social media and the main Watson website.
 */

import React from 'react'

import FinishBlock from '../components/card-finish'
import { getHuntStatus } from '../utils/team-data'
import Slider from 'react-slick'
import CardScore from '../components/card-score'
import CardThanksForPlaying from '../components/card-thanks'
import LoggedInWrapper from '../components/loggedinWrapper'
import AppContext from '../utils/context'
import CardStarRating from '../components/card-star-rating'

export default class FinishPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hunt_status: ``,
    }
    this.afterFinish = this.afterFinish.bind(this)
    this.nextSlide = this.nextSlide.bind(this)
  }

  componentDidMount() {
    this.setState(
      {
        hunt_status: getHuntStatus(),
      },
      () => {
        this.setLoading(false)
      }
    )
  }

  nextSlide() {
    this.slider.slickNext()
  }

  afterFinish() {
    console.log(`finished`)
    this.setState({ hunt_status: getHuntStatus() })
    window.scrollTo(0, 0) // scroll back to the top
  }

  render() {
    var sliderSettings = {
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      useTransform: true,
    }
    return (
      <AppContext.Consumer>
        {({ setLoading }) => {
          this.setLoading = setLoading
          return (
            <LoggedInWrapper>
              <h1 className="sr-only">Finished?</h1>
              {this.state.hunt_status == `ended` ? (
                <div className={`mt-4`}>
                  <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
                    <div className={`px-4`}>
                      <CardScore nextFunc={this.nextSlide} />
                    </div>
                    <div className={`px-4`}>
                      <CardStarRating nextFunc={this.nextSlide} />
                    </div>
                    <div className={`px-4`}>
                      <CardThanksForPlaying />
                    </div>
                  </Slider>
                </div>
              ) : (
                <div className={`mt-4`}>
                  <FinishBlock afterFinish={this.afterFinish} />
                </div>
              )}
            </LoggedInWrapper>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
