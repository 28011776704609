/**
 * Star Rating Card
 * 
 * Asks the user to rate the hunt. Shown on the Finish Page.
 */

import React from 'react'
import { isPermanentCode } from '../utils/team-data'
import { getCurrentHuntData } from '../utils/hunt-data'
import Image from './image'
import SVGLoader from './svg/loader'
import { getCurrentUser } from '../utils/auth'
import API from '../utils/api'
import StarRatings from 'react-star-ratings'

export default class CardStarRating extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      huntData: getCurrentHuntData(),
      isPermanent: isPermanentCode(),
      feedback_type: 3, // 3 is rating type
      rating: 0,
      comment: ``,
      feedbackSubmitted: false,
      loading: false,
      errors: ``,
    }
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.ratingChanged = this.ratingChanged.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({ loading: true })
    let hunt_data = getCurrentHuntData()
    const { name, guid } = getCurrentUser()

    if(this.state.rating == 0){
      this.setState({
        loading: false,
        errors: `Please select a rating.`,
      })
      return
    }

    const self = this

    const post_data = new FormData()
    post_data.append(`feedback_type`, this.state.feedback_type)
    post_data.append(`comment`, this.state.comment)
    post_data.append(`stars`, this.state.rating)
    post_data.append(`hunt_id`, hunt_data.hunt.id)
    post_data.append(`name`, name)
    post_data.append(`guid`, guid)

    API.post(`feedback/`, post_data)
      .then(res => {
        console.log(res)
        this.setState({
          feedbackSubmitted: true,
          loading: false,
          comment: ``,
          rating: 0,
          errors: ``,
        })
      })
      .catch(function(error) {
        console.log(error)
        self.setState({ loading: false })
      })
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  ratingChanged(newRating) {
    this.setState({ rating: newRating })
    if(newRating > 0){
      this.setState({ errors: `` })
    }
  }

  render() {
    const { loading, huntData, feedbackSubmitted, errors } = this.state
    console.log(huntData)

    return (
      <>
        <div className="card card-green">
          <div className="card-body text-center justify-content-between">
            {feedbackSubmitted ? (
              <>
                <div style={{ maxWidth: `225px`, width: `225px` }} className={`mb-4 text-center mx-auto`}>
                  <Image alt="Hands clapping" filename="hands_clap.png" />
                </div>

                <p
                  className={`text-right text-md-center`}
                  style={{
                    fontFamily: `"ff-good-web-pro-extra-conden", GoodPro-XCondBlack, sans-serif`,
                    textTransform: `uppercase`,
                    fontWeight: `800`,
                    fontSize: `23px`,
                    color: `#273A41`,
                    letterSpacing: `2.04px`,
                    lineHeight: `28px`,
                  }}>
                  Thanks for your feedback
                </p>
              </>
            ) : (
              <>
                <div className={`row mt-1 mb-4 align-items-center justify-content-center`}>
                  {
                    // Remove telescope man from Princeton games where the hunt template ID is 707
                  }
                  {huntData[`hunt`][`hunt_template_id`] !== 707 &&
                  <div className={`col-5`}>
                    <div
                      style={{
                        maxWidth: `200px`,
                      }}>
                      <Image alt="Site Feedback" filename="telescope-man.png" />
                    </div>
                  </div> }
                  <div className={`col-7 pr-0`}>
                    <h6 className={`title h5 text-uppercase`}>Did you have fun?</h6>
                    <p>Please rate this game and share your thoughts.</p>
                  </div>
                </div>
                <form
                  method="post"
                  onSubmit={event => {
                    this.handleSubmit(event)
                  }}>
                  <div className={`mb-3`}>
                    <div className={`d-inline-block mx-auto`}>
                      <StarRatings
                        rating={this.state.rating}
                        changeRating={this.ratingChanged}
                        numberOfStars={5}
                        name="rating"
                        starEmptyColor="rgb(178, 196, 178)"
                        starRatedColor="rgb(202,152,49)"
                        starHoverColor="rgb(202,152,49)"
                        starDimension="40px"
                        starSpacing="5px"
                        svgIconPath="M19.46 1a.808.808 0 00-.724.565l-4.03 12.322H1.802v.002a.8.8 0 00-.76.562.83.83 0 00.284.914l10.484 7.813-4.03 12.752v-.002a.825.825 0 00.293.916c.283.21.664.208.944-.002L19.5 29.03l10.484 7.812c.28.21.661.211.944.002a.825.825 0 00.293-.916l-4.03-12.752 10.484-7.812v.001a.83.83 0 00.285-.914.8.8 0 00-.76-.562H24.292l-4.03-12.322v-.002a.805.805 0 00-.802-.564h0z"
                        svgIconViewBox="0 0 39 38"
                      />
                    </div>
                  </div>
                  {errors.length != 0 && <div className={`errors text-danger mb-3`}>{errors}</div>}

                  <div className={`form-group border-bottom-0`}>
                    <label className={`sr-only`} htmlFor="comment">
                      comment
                    </label>
                    <textarea
                      type="text"
                      rows="5"
                      name="comment"
                      className={`form-control form-control-textarea px-4 py-3`}
                      value={this.state.comment}
                      onChange={this.handleUpdate}
                      placeholder="Why did you choose that rating? Anything in particular you liked or thought could be better?"
                    />
                  </div>
                  {loading ? (
                    <p className={`text-center pt-4`}>
                      <SVGLoader className={`text-primary`} />
                    </p>
                  ) : (
                    <input className={`btn btn-block btn-primary`} type="submit" value="Submit" />
                  )}
                </form>
              </>
            )}
          </div>
          <div className="card-footer bg-transparent border-0">
            <button
              className={`btn btn-block btn-primary btn-arrow text-uppercase mt-auto`}
              onClick={this.props.nextFunc}>
              Next
            </button>
          </div>
        </div>
      </>
    )
  }
}
